import { routes } from './routes-constants'

const NO_PERMISSION = "You don't have permissions"

function accessObject(value) {
    const keys = value.split('.')
    let current = routes
    for (const key of keys) {
        if (current[key]) {
            current = current[key]
        } else {
            return undefined
        }
    }
    return current
}

export default function (context) {
    const uri = {}
    // check the user permissions, if return false, stop sending request to server
    const checkUserPermission = (type, url) => {
        // if the user is Super Admin, always return true since Super Admin can do anything in the system
        if (
            (context.$cookie?.get('user') &&
                context.$cookie?.get('user').user_type === 1) ||
            !context.$cookie?.get('token')
        )
            return true
        // for update user profile
        if (url === '/users') return true
        if (url.includes('users/me/info')) return true
        if (url === '/users/session/logout') return true
        if (
            (type === 'get' &&
                (url === '/users/permissions' ||
                    url.includes('/language-strings') ||
                    url === '/currency')) ||
            url.includes('/languages') ||
            url.includes('/master') ||
            url.includes('csl-master/master')
        ) {
            return true
        }
        const userPermissions = context.store.state.settings.userPermissions
        const currentPermissionCode =
            context.store.state.settings.currentPermissionCode
        // getting my user permissions object
        const permissionsByCurrentPermissionCode = userPermissions.find(
            (el) => el.code === currentPermissionCode
        )
        if (!permissionsByCurrentPermissionCode) {
            if (
                url === '/users/permissions' ||
                url.includes('/language-strings') ||
                url.includes('login') ||
                url.includes('verify-tfa-otp') ||
                url === '/currency' ||
                url === '/users/session/logout' ||
                url === '/users' ||
                url === 'users/me/info'
            ) {
                return true
            }
            return false
        }

        if (type === 'get') return permissionsByCurrentPermissionCode.can_view
        if (type === 'post')
            return permissionsByCurrentPermissionCode.can_create
        if (type === 'delete')
            return permissionsByCurrentPermissionCode.can_delete
        if (type === 'put') return permissionsByCurrentPermissionCode.can_update
    }
    // converting local time to UTC time
    const utcTime = (date) => {
        if (date) {
            return context.$dayjs(date).utc().format('YYYY-MM-DD HH:mm:ss')
        }
        return context.$dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
    }
    context.$axios.onRequest((config) => {
        // Getting paths for dynamic routes
        const result = accessObject(config.url)
        if (result?.user) config.url = result?.user

        uri.url = config.url
        uri.params = config.params
        uri.domain = context.$config.API
        uri.method = config.method
        uri.username = config?.data?.username
        uri.data = config?.data
        uri.fullPath = context.route.fullPath

        const params = { ...config.params } || {}
        // This param is for DateButtonFilters Component purpose only
        if (params.selectedDate) params.selectedDate = null

        // Check if the request have dates
        if (params.start_date) params.start_date = utcTime(params.start_date)
        if (params.end_date) params.end_date = utcTime(params.end_date)
        if (params?.start_month) params.start_date = params.start_month
        if (params?.end_month) params.end_date = params.end_month
        // What is keepCurrency for?
        // Don't use the global currency
        if (params.currency && !params.keepCurrency)
            params.currency =
                context.store.state.settings.globalSelectedCurrency
        if (params?.notFire) delete params.notFire

        // To remove null values from parameter
        for (const [key, value] of Object.entries(params)) {
            if (value || value === false) {
                config.params[key] = value
            } else {
                delete config.params[key]
            }
        }
        if (config?.params?.start_month) delete config.params.start_month
        if (config?.params?.end_month) delete config.params.end_month
        if (params?.keepCurrency) delete config.params.keepCurrency
        if (params?.notFire || params?.notFire === false)
            delete config.params.notFire
        if (checkUserPermission(config.method, config.url) === false) {
            throw new Error(NO_PERMISSION) // Stop processing the request
        }
        config.baseURL = context.app.$config.API
        config.headers.common.Authorization = `Bearer ${context.$cookie?.get(
            'token'
        )}`
        if (context.$cookie?.get('currency')) {
            config.headers.common.currency = `${context.$cookie?.get(
                'currency'
            )}`
        }
    })

    // Check if response is in JSON.
    context.$axios.interceptors.response.use(
        (response) => {
            // The success toast will work only when the response is in error message format
            if (
                typeof response?.data?.code === 'number' &&
                response?.data?.error === false
            ) {
                const {
                    message: responseMessage,
                    code: responseMessageStatusCode,
                } = response.data

                const errorStrings =
                    context.store.state['website-setting']['error-code']
                        .errorStrings
                const displayMessageText =
                    errorStrings[responseMessageStatusCode] || responseMessage
                context.$toast.success(`${displayMessageText}`)
            }
            const isExist =
                response.headers['content-type']?.indexOf('application/json')
            return isExist !== -1 ? response : Promise.reject(response)
        },
        (error) => Promise.reject(error)
    )

    // Error handling
    context.$axios.onError((error) => {
        const {
            error: errorStatus,
            code: errorCode,
            message: errorMessage,
        } = error?.response?.data || {}

        const errorStrings =
            context.store.state['website-setting']['error-code'].errorStrings
        const errorMessageByErrorString = errorStrings[errorCode]
        const displayErrorText = errorMessageByErrorString || errorMessage

        // Once user Unauthorized then moved them to login page.
        if (
            error.response?.status === 401 ||
            (error.response?.status === 403 &&
                error?.response?.data?.code === 10910)
        ) {
            // 10910 is the forbidden IP error code
            const isHttpRequestFailByCredentialOrAuth =
                context.store.state.settings.isHttpRequestFailByCredentialOrAuth
            context.$cookie.remove('token')
            context.$cookie.remove('user')

            context.app.router.push('/login')

            if (isHttpRequestFailByCredentialOrAuth === false) {
                context.$toast.clear()
                context.$toast.error(displayErrorText)

                if (!error?.request?.responseURL?.includes('/login')) {
                    context.store.commit(
                        'settings/setIsHttpRequestFailByCredentialOrAuth',
                        true
                    )
                }
            }
            return false
        }

        // Clear old toast before showing the new one
        if (error?.response) {
            // Do not clear the existing toast, if the request is CancelByToken
            context.$toast.clear()
        }

        // Why not putting this toast in the THROW ERROR case, because I need toast.clear() comes after this toast
        if (error?.message === NO_PERMISSION) {
            context.$toast.error(NO_PERMISSION)
            context.store.dispatch('settings/redirectToHasPermissionRoute')
        } else if (error?.response && error?.response?.data) {
            try {
                // Only if the error-message is error;
                if (errorStatus) {
                    context.$toast.error(displayErrorText)
                }
            } catch (error) {}
        } else if (error.message !== '') {
            context.$toast.error('Try again after some time!')
        }
        if (error.response?.status === 403) {
            const userData = context.$cookie.get('user') || {}
            userData.user_type = 2
            context.$cookie.set('user', userData)
            context.store.dispatch('settings/redirectToHasPermissionRoute')
        }
        return false
    })
}
